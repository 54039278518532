@font-face {
font-family: '__gtWalshiemAvidRegular_21f001';
src: url(https://uat-werp.avid.com/_next/static/media/31ec6ac98adca2e9-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__gtWalshiemAvidRegular_Fallback_21f001';src: local("Arial");ascent-override: 88.73%;descent-override: 24.16%;line-gap-override: 0.00%;size-adjust: 101.43%
}.__className_21f001 {font-family: '__gtWalshiemAvidRegular_21f001', '__gtWalshiemAvidRegular_Fallback_21f001'
}.__variable_21f001 {--font-gtWalshiemAvidRegular: '__gtWalshiemAvidRegular_21f001', '__gtWalshiemAvidRegular_Fallback_21f001'
}

@font-face {
font-family: '__gtWalshiemAvidLight_34eb48';
src: url(https://uat-werp.avid.com/_next/static/media/7702e97402f64c71-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__gtWalshiemAvidLight_Fallback_34eb48';src: local("Arial");ascent-override: 89.64%;descent-override: 24.40%;line-gap-override: 0.00%;size-adjust: 100.40%
}.__className_34eb48 {font-family: '__gtWalshiemAvidLight_34eb48', '__gtWalshiemAvidLight_Fallback_34eb48'
}.__variable_34eb48 {--font-gtWalshiemAvidLight: '__gtWalshiemAvidLight_34eb48', '__gtWalshiemAvidLight_Fallback_34eb48'
}

@font-face {
font-family: '__gtWalshiemAvidMedium_a38f6e';
src: url(https://uat-werp.avid.com/_next/static/media/94a041a9f84568c8-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__gtWalshiemAvidMedium_Fallback_a38f6e';src: local("Arial");ascent-override: 87.85%;descent-override: 23.92%;line-gap-override: 0.00%;size-adjust: 102.45%
}.__className_a38f6e {font-family: '__gtWalshiemAvidMedium_a38f6e', '__gtWalshiemAvidMedium_Fallback_a38f6e'
}.__variable_a38f6e {--font-gtWalshiemAvidMedium: '__gtWalshiemAvidMedium_a38f6e', '__gtWalshiemAvidMedium_Fallback_a38f6e'
}

@font-face {
font-family: '__gtWalshiemAvidBold_a09465';
src: url(https://uat-werp.avid.com/_next/static/media/a8a95904f93ba52b-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__gtWalshiemAvidBold_Fallback_a09465';src: local("Arial");ascent-override: 82.44%;descent-override: 22.44%;line-gap-override: 0.00%;size-adjust: 109.17%
}.__className_a09465 {font-family: '__gtWalshiemAvidBold_a09465', '__gtWalshiemAvidBold_Fallback_a09465'
}.__variable_a09465 {--font-gtWalshiemAvidBold: '__gtWalshiemAvidBold_a09465', '__gtWalshiemAvidBold_Fallback_a09465'
}

@font-face {
font-family: '__gtWalshiemAvidRegularOblique_7c5bc8';
src: url(https://uat-werp.avid.com/_next/static/media/eb89774930d86e39-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__gtWalshiemAvidRegularOblique_Fallback_7c5bc8';src: local("Arial");ascent-override: 88.73%;descent-override: 24.16%;line-gap-override: 0.00%;size-adjust: 101.43%
}.__className_7c5bc8 {font-family: '__gtWalshiemAvidRegularOblique_7c5bc8', '__gtWalshiemAvidRegularOblique_Fallback_7c5bc8'
}.__variable_7c5bc8 {--font-gtWalshiemAvidRegularOblique: '__gtWalshiemAvidRegularOblique_7c5bc8', '__gtWalshiemAvidRegularOblique_Fallback_7c5bc8'
}

